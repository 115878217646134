<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        md8
        style="margin-bottom:80px;max-width:850px;"
      >
        <h2 class="mb-10 primary--text">
          ログインフォーム
        </h2>
        <v-card
          :class="['outset', 'welfare-card']"
        >
          <v-row class="py-8">
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <div>
                <img
                  src="@/assets/img/logo_lg.png"
                  style="max-width:500px; width:100%;"
                  class="ml-5 mt-5"
                >
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="py-3 pr-10"
            >
              <div>
                <v-card-text class="pt-0">
                  <v-form>
                    <v-text-field
                      v-model="email"
                      prepend-icon="mdi-account"
                      name="login"
                      label="メールアドレス"
                      type="text"
                    />
                    <v-text-field
                      v-model="password"
                      prepend-icon="mdi-lock"
                      name="password"
                      label="パスワード"
                      type="password"
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-row class="text-center">
                    <v-col
                      cols="12"
                      class="pt-0"
                    >
                      <v-btn
                        color="primary"
                        :outlined="true"
                        :class="'outset'"
                        :style="{'border-radius': '20px'}"
                        :disabled="isDisabledSignIn"
                        width="200"
                        @click="signIn"
                      >
                        ログイン
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="12"
                      class="black--text pa-0 pt-2 text-sm-body-2"
                    >
                      または
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        color="primary"
                        :outlined="true"
                        :class="'outset'"
                        :style="{'border-radius': '20px'}"
                        :loading="loading"
                        @click="signInWithGoogle"
                      >
                        <v-icon
                          small
                          class="mr-2"
                          style="text-transform: none;"
                        >
                          mdi-google
                        </v-icon>Googleアカウントでログイン
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',

  data: () => ({
    email: '',
    password: ''
  }),

  computed: {
    ...mapGetters([
      'loading'
    ]),
    styles () {
      return {
        bg: {
          color: this.$vuetify.theme.themes.light.primary,
          'border-radius': '20px',
          'background-color': this.$vuetify.theme.themes.light.background
        }
      }
    },
    isDisabledSignIn () {
      return !(this.email.length > 0 && this.password.length > 0)
    }
  },

  created () {
    this.$store.commit('setContentsOnly', { flag: true })
  },

  mounted () {
    this.$store.commit('signInCallback')
  },

  methods: {
    signIn () {
      this.$store.commit('signIn', { email: this.email, password: this.password })
    },
    signInWithGoogle () {
      this.$store.commit('signInWithGoogle')
    }
  }
}
</script>
